import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Registro } from 'src/app/Models/registro.interface';
import { RegistroService } from 'src/app/services/registro.service';

@Component({
  selector: 'app-modal-registro',
  templateUrl: './modal-registro.page.html',
  styleUrls: ['./modal-registro.page.scss'],
})
export class ModalRegistroPage implements OnInit {

  isSubmitted = false;
  datosDynamics: any = [];
  customerID!: string;
  correo!: string;
  regis: Registro;
  isLoading = false;
  newsActive: any;
  newsletter: number;
  regisnew: any;
  facturame = false;
  UsoCFDI:any;
  usocfdi:any;
  regimenFiscal:any;
  ciudadilla:any;

  codiguillo: any =[];
  arrayCiudades:any=[];
  arrayColonias:any =[]
  codigoEscrito = 0;
  arraicitoCiudades:any =[];
  arraisitoColonias:any=[]
  arraicitoEstado:any =[];
  jsonReg:any=[];
  ciudadsinCodigo:any;
  nombreColonia:any;
  ciudadSeleccionada:any;
    countyId:any;
  rfc :any;
  captcha ="";

  constructor(
    private modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public router: Router,
    public registroService: RegistroService, private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  regisForm: UntypedFormGroup;

  ngOnInit() {
    this.regisForm = this.formBuilder.group({
      nombre:    ['', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), Validators.minLength(2), Validators.maxLength(36)]],
      apellidos: ['', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), Validators.minLength(5), Validators.maxLength(36)]],
      rfc:       ['', [Validators.pattern('^[A-Za-z0-9]+$'), Validators.minLength(12), Validators.maxLength(13)]],
      razon:     [''],
      email:     ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password:  ['',Validators.required],
      /* https://localcoder.org/how-to-validate-password-strength-with-angular-5-validator-pattern  */
      /* 
      - At least 8 characters in length
      - Lowercase letters
      - Uppercase letters
      - Numbers
      - Special characters
      */
      news:      [false],
      priv:      [false, [Validators.pattern('true')]],
      term:      [false, [Validators.pattern('true')]],
      zipcode: ['', Validators.required],
      stateValue: [''],
      cityValue: [''],
      colonyValue: [''],
      countryValue: [''],
      alias: ['',Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      phonenumber: ['',Validators.required],
      correo2: ['']
    });
  }



  register(){
    this.presentLoading()
    this.isSubmitted = true;
    if (this.regisForm.invalid) {
      
      
      return;
    }
    this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
      this.captcha = token;
   
      if (this.captcha =="") {
        return;
      }
    });
      this.regis = this.regisForm.value;
      this.newsActive = this.regis.news;
      //console.log(this.newsActive);
      if (this.newsActive == true) {
        this.newsletter = 1;
      } else {
        this.newsletter = 0;
      }

      this.regis.newsletter = this.newsletter;

      
      //this.SpinnerService.show();
      this.registroService.registroUsuario(this.regis).subscribe(response =>{
        console.log(response)
        if(response == 1){
          
          this.addUserDynamics(this.regis);
          this.dismiss();
          
        }
        if(response == 2){
          this.dismiss();
          //this.errorNotificationCorreo();
          //this.SpinnerService.hide();
          
        }
      
      });
    
    
  }


  imprimir(){
    var separame = this.regisForm.controls.colonyValue.value.split('-');
    var a = separame[0];
    var b = separame[1]
    this.nombreColonia = a;
    this.countyId = b;
    console.log(this.nombreColonia)
    console.log(this.countyId)

  }

  facturado(){
    if (this.facturame == false) {
      this.facturame = true;
      
    }else{
      this.facturame = false;
    }

  }


  registrame(){
    if (this.regisForm.invalid) {
      return;
    }else{
      // this.cargando = true;
        // this.submittedAddress = true;
        this.registroService.registroUsuario(this.regisForm.value).subscribe(response =>{
          if(response == 1){


            // var nombre = datosUsuario[0].toUpperCase();
            // var apellidos = datosUsuario[1].toUpperCase();
            // var rfc = datosUsuario[2].toUpperCase();
            // var email = datosUsuario[4].toUpperCase();
      
        var correoUsuario: any = Object.values(this.regisForm.value);
        var emailUsuario = correoUsuario[4];
        var ciudad = this.ciudadSeleccionada
        this.regisForm.controls.cityValue.setValue(ciudad);
        // var colonia = (<HTMLSelectElement>document.getElementsByName("colony")[0]).selectedOptions[0].innerText;
        // var estado = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;
        var phone = correoUsuario[17];
        // var nc = colonia.split(' ')
        // nc.pop()
        // var coloniaFinal = nc.join(' ')
        // this.regisForm.controls.colonyValue.setValue(coloniaFinal);
        this.regisForm.controls.correo2.setValue(emailUsuario);
        // this.regisForm.controls.stateValue.setValue(estado);
        this.regisForm.controls.phonenumber.setValue(phone);
        this.registroService.enviaDireccion(this.regisForm.value).subscribe(data => {
  
          var correoUsuario: any = Object.values(this.regisForm.value);
          var emailUsuario = correoUsuario[4];
          this.regisForm.controls.correo2.setValue(emailUsuario);
          // this.addUserDynamics(this.registroForm.value);
          // this.cargando = true;
  
          var datosUsuario:any = Object.values(this.regisForm.value);
      
          this.rfc = datosUsuario[2];
          // this.regimenFiscal = datosUsuario[9];
          // this.usoCfdi = datosUsuario[10];
      
          if(datosUsuario[2] == '' || datosUsuario[2] == null ){
             this.rfc = "XAXX010101000";
      
          }
          if(this.regimenFiscal == '' || this.regimenFiscal == null ){
            this.regimenFiscal  = "616";
        
      
         }
         if(this.usocfdi  == '' || this.usocfdi  == null ){
          this.usocfdi  = "S01";
      
       }
      
          var nombre = datosUsuario[0].toUpperCase();
          var apellidoPat = datosUsuario[1].toUpperCase();
          // var apellidoMat = datosUsuario[2].toUpperCase();
          // var rfc = datosUsuario[4].toUpperCase();
          var correoElectronico = datosUsuario[4];
      
      
         
         var datosDireccion:any = Object.values(this.regisForm.value);
         var alias = datosDireccion[14].toUpperCase();
         var codigoPostal = datosDireccion[9];
         var direccion = datosDireccion[15].toUpperCase();
         var direccionComp = datosDireccion[16].toUpperCase();
         var pais = datosDireccion[13].toUpperCase();
         var estado = datosDireccion[10].toUpperCase();
         var ciudad =this.ciudadSeleccionada;
         var colonia = datosDireccion[12].toUpperCase();
         var telefono = datosDireccion[17];
      
          var countyJson = {
            "codigo_postal":codigoPostal,
            "asentamiento":datosDireccion[14]
      
          }
            // this.usuariosService.checaCountyID(JSON.stringify(countyJson)).subscribe(countyID=>{
            //   this.datoscounty = countyID;
      
            //   this.countyId = this.datoscounty[0].CountyId;
      
            //   if (this.countyId == null || this.countyId == undefined) {
            //     this.countyId = this.datoscounty[0].id_codigo_postal+"_"+codigoPostal;
            //   }
                
      
              // var jsonUsuario = {
              //   "CustomerAccount": "",
              //   "OrganizationName": nombre+" "+apellidoPat,
              //   "SalesCurrencyCode": "MXN",
              //   "CustomerGroupId": "CONTADO",
              //   "SalesDistrict": "CHIH",
              //   "CompanyType": "2",
              //   "RFCNumber": this.rfc,
              //   "SiteId": "CHIH",
              //   "LineDiscountCode": "LID SUB 2",
              //   "SalesSegmentId": "MANUALIDAD",
              //   "PrimaryContactPhone": telefono,
              //   "PrimaryContactEmail": correoElectronico,
              //   "satPurpose_MX": this.usocfdi,
              //   "Regimen":Number(this.regimenFiscal),
              //   "Direcciones": [
              //   {
              //   "dataAreaId": "LIN",
              //   "customerLegalEntityId": "LIN",
              //   "customerAccountNumber": "",
              //   "addressDescription": alias,
              //   "addressZipCode": codigoPostal,
              //   "addressState": this.ciudadilla.toUpperCase(),
              //   "addressCountryRegionId": "MEX",
              //   "addressCity": this.ciudadSeleccionada.toUpperCase(),
              //   "addressStreet": direccion,
              //   "addressStreetNumber": "",
              //   "addressCountyId":this.countyId,
              //   "addressCountyName": this.nombreColonia.toUpperCase(),
              //   "addressLocationRoles": "Business",
              //   "isPrimary": "Yes",
              //   "addressUniqueId": "New"
              //   }
              //   ],
              //   "Contactos": [
              //   {
              //   "partyNumber": "0",
              //   "description": nombre,
              //   "locator": telefono,
              //   "locatorExtension": "",
              //   "type": "1",
              //   "isPrimary": "Yes",
              //   "partyUniqueId": "New"
              //   },
              //   {
              //     "partyNumber": "0",
              //     "description": nombre,
              //     "locator": correoElectronico,
              //     "locatorExtension": "",
              //     "type": "2",
              //     "isPrimary": "Yes",
              //     "partyUniqueId": "New"
              //     }
              //   ]
              //   }
                var jsonUsuario =
                {
                  "CustomerAccount":"",
                "OrganizationName": nombre+" "+apellidoPat,
                "SalesCurrencyCode":"MXN",
                "CustomerGroupId":"CONTADO",
                "SalesDistrict":"CHIH",
                "CompanyType":"2",
                "RFCNumber":this.rfc,
                "SiteId":"CHIH",
                "LineDiscountCode":"LID SUB 2",
                "SalesSegmentId":"MANUALIDAD",
                "Direcciones":[
                  {
                  "dataAreaId":"LIN",
                  "customerLegalEntityId":"LIN",
                  "customerAccountNumber":"",
                  "addressDescription":alias,
                  "addressZipCode":codigoPostal,
                  "addressState":this.ciudadilla.toUpperCase(),
                  "addressCountryRegionId":"MEX",
                  "addressCity":this.ciudadSeleccionada.toUpperCase(),
                  "addressStreet":direccion,
                  "addressStreetNumber":"",
                  "addressCountyId":this.countyId,
                  "addressCountyName":this.nombreColonia.toUpperCase(),
                  "addressLocationRoles":"Delivery;Business;Invoice",
                  "isPrimary":"Yes",
                  "addressUniqueId":"New"
                }
                ],
                "Contactos":[
                  {
                    "partyNumber":"0",
                    "description":nombre,
                    "locator":telefono,
                    "locatorExtension":"",
                    "type":"1",
                    "isPrimary":"Yes",
                    "partyUniqueId":"New"
                  },
                    {
                    "partyNumber":"0",
                    "description":nombre,
                    "locator":correoElectronico,
                    "locatorExtension":"",
                    "type":"2",
                    "isPrimary":"No",
                    "partyUniqueId":"New"
                  }
                ],
                    "Regimen":this.regimenFiscal,
                    "SATPurpose_MX":this.usocfdi
                  }
            
                  this.presentLoading();
              this.registroService.registroDynamics(JSON.stringify(jsonUsuario)).subscribe(dataUser => {
                //console.log(dataUser);
                if(dataUser.exito == 1){
                  this.datosDynamics = Object.values(dataUser);
                  var  arrayDatos = JSON. parse(this.datosDynamics[2]);
        
                  this.customerID = arrayDatos.CustomerAccount;
                  this.correo = correoElectronico;
                  // this.correo = arrayDatos.PrimaryContactEmail;
        
                  var datosJson = {
                    'customerID' : this.customerID,
                    'correo' : this.correo
                  }
        
                  this.registroService.actualizaCustomerID(JSON.stringify(datosJson)).subscribe(data=>{});
                  // this.cargando = false;
                 
                  
                  this.router.navigate(['/login']);
                  this.dismiss();
                }
                
              });
      
            
      
      
    
        });
            
          }
          if(response == 2){
            this.ErroCorreo();
            // this.cargando = false;
          }
         
        });
   
    
  
    }
  
  
  
  }

  mandaDatos(county:any,nombreColonia:any){

    this.countyId = county
    this.nombreColonia = nombreColonia;
    
    }

  buscameXCodigoPostal(codigo:any){
    try {
      

    this.cargandoCosas();
    this.registroService.getEstados(codigo).subscribe(codigazo =>{
      this.arraicitoEstado = codigazo
      this.codiguillo = this.arraicitoEstado.data;
      if (this.codiguillo == null || this.codiguillo == "") {
        this.ErrorCodigo();
        // this.cargando = false;
        this.codigoEscrito = 0;
      }else{
        this.codigoEscrito = 1;
        this.ciudadilla = this.codiguillo.stateId;
        if (this.ciudadilla == 'AGS'){ this.ciudadsinCodigo ="Aguascalientes"}
        if (this.ciudadilla == 'BCA'){ this.ciudadsinCodigo ="Baja California"}
        if (this.ciudadilla == 'BCS'){ this.ciudadsinCodigo ="Baja California Sur"}
        if (this.ciudadilla == 'CAM'){ this.ciudadsinCodigo ="Campeche"}
        if (this.ciudadilla == 'CHI'){ this.ciudadsinCodigo ="Chiapas"}
        if (this.ciudadilla == 'CHH'){ this.ciudadsinCodigo ="Chihuahua"}
        if (this.ciudadilla == 'COA'){ this.ciudadsinCodigo ="Coahuila"}
        if (this.ciudadilla == 'COL'){ this.ciudadsinCodigo ="Colima"}
        if (this.ciudadilla == 'DIF'){ this.ciudadsinCodigo ="Distrito Federal"}
        if (this.ciudadilla == 'DGO'){ this.ciudadsinCodigo ="Durango"}
        if (this.ciudadilla == 'GTO'){ this.ciudadsinCodigo ="Guanajuato"}
        if (this.ciudadilla == 'GRO'){ this.ciudadsinCodigo ="Guerrero"}
        if (this.ciudadilla == 'HGO'){ this.ciudadsinCodigo ="Hidalgo"}
        if (this.ciudadilla == 'JAL'){ this.ciudadsinCodigo ="Jalisco"}
        if (this.ciudadilla == 'CDMX'){ this.ciudadsinCodigo ="Estado de México"}
        if (this.ciudadilla == 'MIC'){ this.ciudadsinCodigo ="Michoacán"}
        if (this.ciudadilla == 'MOR'){ this.ciudadsinCodigo ="Morelos"}
        if (this.ciudadilla == 'NAY'){ this.ciudadsinCodigo ="Nayarit"}
        if (this.ciudadilla == 'NLE'){ this.ciudadsinCodigo ="Nuevo León"}
        if (this.ciudadilla == 'OAX'){ this.ciudadsinCodigo ="Oaxaca"}
        if (this.ciudadilla == 'PUE'){ this.ciudadsinCodigo ="Puebla"}
        if (this.ciudadilla == 'QRO'){ this.ciudadsinCodigo ="Querétaro"}
        if (this.ciudadilla == 'ROO'){ this.ciudadsinCodigo ="Quintana Roo"}
        if (this.ciudadilla == 'SLP'){ this.ciudadsinCodigo ="San Luis Potosí"}
        if (this.ciudadilla == 'SIN'){ this.ciudadsinCodigo ="Sinaloa"}
        if (this.ciudadilla == 'SON'){ this.ciudadsinCodigo ="Sonora"}
        if (this.ciudadilla == 'TAB'){ this.ciudadsinCodigo ="Tabasco"}
        if (this.ciudadilla == 'TAM'){ this.ciudadsinCodigo ="Tamaulipas"}
        if (this.ciudadilla == 'TLA'){ this.ciudadsinCodigo ="Tlaxcala"}
        if (this.ciudadilla == 'VER'){ this.ciudadsinCodigo ="Veracruz"}
        if (this.ciudadilla == 'YUC'){ this.ciudadsinCodigo ="Yucatán"}
        if (this.ciudadilla == 'ZAC'){ this.ciudadsinCodigo ="Zacatecas"}
      
        this.registroService.getCiudades(codigo,this.ciudadilla).subscribe(ciudadcillas =>{
        
          this.arraicitoCiudades = ciudadcillas
          this.arrayCiudades =  this.arraicitoCiudades.data;
          this.ciudadSeleccionada= this.arraicitoCiudades.data[0].ciudadSeleccionada


          this.registroService.getColonias(codigo).subscribe(coloniecillas =>{
            this.arraisitoColonias = coloniecillas
            this.arrayColonias = this.arraisitoColonias.data;



            // this.cargando =false;
          })
        })
      }
    
    })
  } catch (error) {
      // this.cargando = false;
  }
  }

  async presentLoading() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Registrando Usuario...',
      duration: 3000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
    
  }

  async cargandoCosas() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Cargando Direccion...',
      duration: 2000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
    
  }

  

  async ErroCorreo() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Este correo ya ha sido registrado.',
      duration: 3000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
    
  }


  async ErrorCodigo() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'No existe el codigo postal ingresado',
      duration: 3000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
    
  }

  addUserDynamics(registroUsuario: any){
    //this.SpinnerService.show();
    var datosUsuario:any = Object.values(registroUsuario);

    var nombre = datosUsuario[0].toUpperCase();
    var apellidos = datosUsuario[1].toUpperCase();
    var rfc = datosUsuario[2].toUpperCase();
    var email = datosUsuario[4].toUpperCase();

    if(rfc == '' || rfc == null ){
      rfc = "XAXX010101000";
    }

    var jsonUsuario = {
        "CustomerAccount": "",
        "OrganizationName": nombre+" "+apellidos,
        "SalesCurrencyCode": "MXN",
        "CustomerGroupId": "CONTADO",
        "SalesDistrict": "CHIH",
        "CompanyType": "2",
        "RFCNumber": rfc,
        "SiteId": "CHIH",
        "LineDiscountCode": "CHIH-D-BS",
        "SalesSegmentId": "COMERCIO",
        "PrimaryContactPhone": "",
        "PrimaryContactEmail": email,
        "Direcciones": [
        {
        "dataAreaId": "LIN",
        "customerLegalEntityId": "LIN",
        "customerAccountNumber": "",
        "addressDescription": "",
        "addressZipCode": "",
        "addressState": "",
        "addressCountryRegionId": "MEX",
        "addressCity": "",
        "addressStreet": "",
        "addressStreetNumber": "",
        "addressCountyId": "",
        "addressCountyName": "",
        "addressLocationRoles": "Business",
        "isPrimary": "Yes",
        "addressUniqueId": "New"
        }
        ],
        "Contactos": [
        {
        "partyNumber": "0",
        "description": nombre,
        "locator": "",
        "locatorExtension": "",
        "type": "1",
        "isPrimary": "Yes",
        "partyUniqueId": "New"
        },
        {
          "partyNumber": "0",
          "description": nombre,
          "locator": email,
          "locatorExtension": "",
          "type": "2",
          "isPrimary": "Yes",
          "partyUniqueId": "New"
          }
        ]
        }
    
      // console.log('Datos = ' + JSON.stringify(jsonUsuario));
      this.registroService.registroDynamics(JSON.stringify(jsonUsuario)).subscribe(dataUser => {
        console.log(dataUser);
        if(dataUser  == 1){
          this.datosDynamics = Object.values(dataUser);
          var  arrayDatos = JSON. parse(this.datosDynamics[2]);

          this.customerID = arrayDatos.CustomerAccount;
          this.correo = arrayDatos.PrimaryContactEmail;

          var datosJson = {
            'customerID' : this.customerID,
            'correo' : this.correo
          }

          this.registroService.actualizaCustomerID(JSON.stringify(datosJson)).subscribe(data=>{});
          //this.SpinnerService.hide();
          //this.exitoRegistro();
          
          this.router.navigate(['/login']);
        }
        
      });
  }

  get errorControl() {
    return this.regisForm.controls;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
