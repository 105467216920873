import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { LoginService } from 'src/app/services/login.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-modal-recuperar',
  templateUrl: './modal-recuperar.page.html',
  styleUrls: ['./modal-recuperar.page.scss'],
})
export class ModalRecuperarPage implements OnInit {


  nombre:any;
  apellido: any;
  link: any;
  datos: any =[];
  correito:any;
  estoycargando =false;




  constructor(
    private modalCtrl: ModalController, private formBuilder: UntypedFormBuilder,private contactoService: LoginService,
    public toastController: ToastController
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }



  sendRequest(){
    this.estoycargando = true;
    this.contactoService.requestReset(this.correito).subscribe(data =>{
      this.datos = data;

      if (this.datos == null || this.datos == undefined || this.datos == "") {
        this.Errorsito();
        this.estoycargando = true;
      }else{
        this.nombre = this.datos.nombre;
        this.apellido = this.datos.lastname;
        this.link = this.datos.link;
        this.correito = this.datos.correo;
        var lineasCorreo: any[] = []
    
        lineasCorreo.push(
          {
            "nombreCliente": this.nombre+ " "+this.apellido,
            "correoCliente": this.correito,
            "link": this.link
          
          }
        )
        this.contactoService.EnviaCorreoRecuperaPassword(lineasCorreo[0]).subscribe(data =>{
         
          this.estoycargando = false;
          this.Success();
        this.correito ="";
        })
 
      }



      
    })


  }


  async Errorsito() {
    const toast = await this.toastController.create({
      message: 'Correo no registrado',
      color: "danger",
      position: "top",
      duration: 1500
      
    });
    toast.present();
  }

  async Success() {
    const toast = await this.toastController.create({
      message: 'Verifica tu correo electronico y sigue los pasos para recuperar tu contraseña',
      color: "success",
      position: "top",
      duration: 1500
      
    });
    toast.present();
  }
}
