import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { DeskFooterComponent } from "./desk-footer/desk-footer.component";
import { DeskHeaderComponent } from "./desk-header/desk-header.component";

@NgModule({
    imports:[
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule
    ],
    declarations: [DeskHeaderComponent, DeskFooterComponent],
    exports: [DeskHeaderComponent, DeskFooterComponent]
})
export class ComponentsModule{}