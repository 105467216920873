import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Producto } from '../Models/Productos';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class CarritoServiceService {

  placeholder: any[] =[];
  cartItems =new BehaviorSubject<any>([]);
  constructor(public http: HttpClient) { 
    const ls = this.getCartData();
    if(ls){
      this.cartItems.next(ls);
    }
  }

  addItem(product: Producto){

    const ls = this.getCartData();
    let exist: any;
    let pasa : any;
  
    pasa = false;
    
    if (ls) {
      ls.find((item:any) =>{
       
        if (!pasa) {
          
          pasa = (item.sku === product.sku);
          if (pasa) {
            exist = item;
          }
          
        }
      });
      if(pasa){
        exist.quantity+= product.quantity;
        this.setCartData(ls);
        
      }else{
        ls.push(product);
        this.setCartData(ls);
      }
    }else{

        const newData = [product];
        this.placeholder.push(product);
        this.setCartData(newData);

    }
  }

  setCartData(data:any){
    localStorage.setItem("cart", JSON.stringify(data));
      this.cartItems.next(this.getCartData());
    }
    
    getCartData(){
      return JSON.parse(localStorage.getItem("cart")+"");
    }
    deleteCart(){
      this.placeholder = [];
      this.cartItems.next(this.placeholder);
      localStorage.removeItem('cart');
    }



    registraNuevoCarrito(email: any){
      return this.http.post<Response>('https://lideart.com.mx/Automatizacion/apis/carritoCreado.php',email,httpOption);
    }
    
    carritoPasoCaja(email: any){
      return this.http.post<Response>('https://lideart.com.mx/Automatizacion/apis/carritoPasoCaja.php',email,httpOption);
    }
    
    carritoPasoUsuario(email: any){
      return this.http.post<Response>('https://lideart.com.mx/Automatizacion/apis/carritoPasoUsuario.php',email,httpOption);
    }
    
    carritoPasoDireccion(email: any){
      return this.http.post<Response>('https://lideart.com.mx/Automatizacion/apis/carritoPasoDireccion.php',email,httpOption);
    }
    
    carritoPasoEnvio(email: any){
      return this.http.post<Response>('https://lideart.com.mx/Automatizacion/apis/carritoPasoEnvio.php',email,httpOption);
    }
    
    carritoFinalizo(email: any){
      return this.http.post<Response>('https://lideart.com.mx/Automatizacion/apis/carritoFinalizo.php',email,httpOption);
    }


}
