import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Direccion } from '../Models/direccion';
import { Registro } from '../Models/registro.interface';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  constructor(
    public http: HttpClient
  ) { }

  registroUsuario(registro:Registro){
    console.log(registro);
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/registro.php',registro);
  }

  registroDynamics(datosUsuario: any): Observable<any>{
    // return this.http.post('https://lideart.aytcloud.com/inaxapiv2/api/cliente/addClientdynamics365',datosUsuario,httpOption);
    return this.http.post('https://inax2.aytcloud.com/inaxapilideart/api/cliente/addClientdynamics365',datosUsuario,httpOption);
  }

  actualizaCustomerID(datos: any): Observable<any> {

    return this.http.post('https://lideart.com.mx/Automatizacion/apis/actualizaCustomerID.php',datos);
  }


  getEstados(codigopostal: any){
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxapilideart/api/cliente/getStateCountry/'+codigopostal);
  }
  getCiudades(codigopostal: any,ciudad:any){
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxapilideart/api/CodigosPostales/ciudadesPostal/'+ciudad+'/'+codigopostal);
  }
  getColonias(codigopostal: any){
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxapilideart/api/codigospostales/cpPostal/'+codigopostal);
  }


  enviaDireccion(direccion: Direccion){
    
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/registraDireccion.php',direccion);

  }

}

