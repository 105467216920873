import { Component, ElementRef, OnInit, Renderer2, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import Fuse from 'fuse.js';
import { Usuario } from 'src/app/Models/usuario.interface';
import { ModalRegistroPage } from 'src/app/pages/modal-registro/modal-registro.page';
import { ApiProductosService } from 'src/app/services/api-productos.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CarritoServiceService } from 'src/app/services/carrito-service.service';
import { LoginService } from 'src/app/services/login.service';
import { ServiciowishlistService } from 'src/app/services/serviciowishlist.service';

@Component({
  selector: 'app-desk-header',
  templateUrl: './desk-header.component.html',
  styleUrls: ['./desk-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeskHeaderComponent implements OnInit {
  ESCAPE_KEYCODE = 27;
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === this.ESCAPE_KEYCODE) {
      if ( this.txtBuscador != '' ) {
        this.txtBuscador = '';
      }
    }
  }

  @ViewChild('nav_cont') nav_cont!:ElementRef<HTMLInputElement>;

  @ViewChild('upMenu') upMenu!:ElementRef<HTMLInputElement>;
  sticky: boolean = false;
  elementPosition: any;

  @ViewChild('pistasCont') pistasCont!:ElementRef<HTMLInputElement>;
  @ViewChild('ud1') ud1!:ElementRef<HTMLInputElement>;
  pistasContIs = false;

  @ViewChild('cardMarcas') cardMarcas!:ElementRef<HTMLInputElement>;
  @ViewChild('ud2') ud2!:ElementRef<HTMLInputElement>;
  cardMarcasIs = false;

  @ViewChild('cardCategorias') cardCategorias!:ElementRef<HTMLInputElement>;
  @ViewChild('ud3') ud3!:ElementRef<HTMLInputElement>;
  cardCategoriasIs = false;

  nav_contIs = false;
  cargando = false;
  respaq:any;
  usuario!: Usuario;
  datosUsuario: any = [];
  nombre!: string;
  apellido!: string;
  public totalItems: number = 0;
  public searchbarVisible: boolean = false;
  public txtBuscador: string = '';
  public productos: any = [];
  public resultadoBusqueda: any = null;
  public numeroResultados: number = 0;
  resultadosBusqueda:any =[]
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre'],
  };

  listaWishlist: any =[];

  constructor( private renderer : Renderer2, private apiProductos : ApiProductosService, private router : Router, private shoppingCart : CarritoServiceService, 
    public modalCtrl : ModalController, public usuarioService : LoginService, private authService : AuthenticationService, private wishlist : ServiciowishlistService ) {
    this.usuarioService.usuario.subscribe(res =>{
      this.usuario = res;
      if (this.usuario) {
        this.checalo();
      }
    });
  }

  ngOnInit() {

    this.apiProductos.getProductos().subscribe((response) => {
      this.productos = response;
    });
    this.shoppingCart.cartItems.subscribe(d=>{
      this.totalItems = d.length;
    });
    if (this.usuario) {
      this.wishlist.recuperaWishlist(this.usuario.usuario).subscribe((response) => {
        this.listaWishlist = response;
       
      });
    }
  }

  handlePistas(){
    if (this.pistasContIs == false) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '0em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-down');

      this.pistasContIs = true;
    }else if (this.pistasContIs == true) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '8em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '1em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-up');

      this.pistasContIs = false;
    }
  }

  handleCard1(){
    if (this.cardMarcasIs == false) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '12em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '.1em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-down');

      this.cardMarcasIs = true;
    }else if (this.cardMarcasIs == true) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '3em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-up');

      this.cardMarcasIs = false;
    }
  }

  handleCard2(){
    if (this.cardCategoriasIs == false) {
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'height', '12em');
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'padding', '.1em');
      this.renderer.removeClass(this.ud3.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud3.nativeElement, 'fa-chevron-down');

      this.cardCategoriasIs = true;
    }else if (this.cardCategoriasIs == true) {
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'height', '3em');
      this.renderer.setStyle(this.cardCategorias.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud3.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud3.nativeElement, 'fa-chevron-up');

      this.cardCategoriasIs = false;
    }
  }

  ngAfterViewInit(){
    this.elementPosition = this.upMenu.nativeElement.offsetTop;
  }

  checalo() {
    this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
      this.datosUsuario = data;


      this.nombre = this.datosUsuario.nombre;
      this.apellido = this.datosUsuario.apellido;
     
    })
  }

  close(){
    this.usuarioService.logout();
    this.authService.logout();
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll(){
      const windowScroll = window.pageYOffset;
    
      if(windowScroll >= this.elementPosition){
        this.renderer.setStyle(this.upMenu.nativeElement, 'position', 'fixed');
      } else {
        this.renderer.setStyle(this.upMenu.nativeElement, 'position', 'relative');
      }
    }

  handleNav(){
    if (this.nav_contIs == false) {
      this.renderer.setStyle(this.nav_cont.nativeElement, 'top', '5.3em');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'visibility', 'visible');

      this.nav_contIs = true;
    } else if (this.nav_contIs == true) {
      this.renderer.setStyle(this.nav_cont.nativeElement, 'top', '-25em');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'opacity', '0');
      this.renderer.setStyle(this.nav_cont.nativeElement, 'visibility', 'hidden');

      this.nav_contIs = false;
    }
  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      buscador.style.display = "none";
    }
  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);
      
      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
     
     
    }
  }

  openSearchBar2(item:any, sku:any) {
   
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
      this.cargando = true;
        this.apiProductos.esPaquete(sku).subscribe(paquetillo =>{
          this.respaq = paquetillo;
          if (this.respaq == 1) {
            this.cargando = false;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(['/descripcion-paquetes/'+item]);
          });
          }
          if (this.respaq == 0) {
            this.cargando = false;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(['/descripcion-producto/'+item]);
          });
          }
  
        
        })

    
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      this.cargando = true;
      buscador.style.display = "none";
      this.apiProductos.getInfoPaquetes(sku).subscribe(paquetillo =>{
        this.respaq = paquetillo;
        if (this.respaq == 1) {
          this.cargando = false;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/descripcion-paquetes/'+item]);
        });
        }
        if (this.respaq == 0) {
          this.cargando = false;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/descripcion-producto/'+item]);
        });
        }

      
      })
      
    }

  }

  async modalReg() {
    const modal = await this.modalCtrl.create({
      component: ModalRegistroPage,
      cssClass: 'modalRegistroCss',
      initialBreakpoint: 1,
      breakpoints: [0, 0.5, 1]
    });
    return await modal.present();
  }


  llevame(item:any, sku:any){
    this.apiProductos.getInfoPaquetes(sku).subscribe(paquetillo =>{
      this.respaq = paquetillo;
      if (this.respaq == 1) {
        this.cargando = false;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/descripcion-paquetes/'+item]);
      });
      }
      if (this.respaq == 0) {
        this.cargando = false;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['/descripcion-producto/'+item]);
      });
      }
  
    
    })
  }


  mevoy(){
    this.resultadosBusqueda= JSON.parse(localStorage.getItem('productos_buscados'));
    this.txtBuscador = "";
    this.apiProductos.changeParam(this.resultadosBusqueda)
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/resultados-busqueda']);
    });
  }
  
}
