import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
const headers = new Headers();

@Injectable({
  providedIn: 'root'
})




export class ServiciowishlistService {

  constructor(public http: HttpClient) { }


  sendWishlist(datosWishlist: any){
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/registraWishlist.php',datosWishlist);
  }

  recuperaWishlist(datosCorreo: any){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/getWishlist.php?datosCorreo='+datosCorreo);
  }

  eliminarWishlist(datosWishlist: any){
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/eliminaWishlist.php',datosWishlist);
  }

  recuperaBlog(){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/blog.php');
  }

  recuperaArticulo(id_post: any){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/articulo-blog.php?id_post='+id_post);
  }
}
