import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';
import { ModalRecuperarPage } from '../modal-recuperar/modal-recuperar.page';
import { ModalRegistroPage } from '../modal-registro/modal-registro.page';
import { DataService } from 'src/app/services/test.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  /*get gifs(){
    return this.dataService.historial;
  }*/

  constructor(
    public modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public usuariosLogin: LoginService,
    public router: Router,
    private authService: AuthenticationService,
    private dataService: DataService
  ) {  
    
  }

  loginForm: UntypedFormGroup; 
  isSubmitted = false;
  isLoading = false;
  items: any=[];

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', [Validators.required]]
      /* https://localcoder.org/how-to-validate-password-strength-with-angular-5-validator-pattern  */
      /* 
      - At least 8 characters in length
      - Lowercase letters
      - Uppercase letters
      - Numbers
      - Special characters
      */
    });

  }

  handleHead(){
    this.router.navigate(['/home']);
    this.dataService.setData(2);
  }

  async presentLoading() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Iniciando Sesion...',
      duration: 50000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
    
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  login() {
    this.isSubmitted = true;
    if (!this.loginForm.valid) {
      //this.presentLoading();
      this.presentAlert();
    } else {
    

      this.presentLoading();
      this.usuariosLogin.login(this.loginForm.value).subscribe(res =>{
        if(res.exito === 1){
          this.dismiss();
          const toka = res.token;
          this.authService.login(toka);
        if(this.items.length>0){
          this.router.navigate(['/carrito']);
          this.dataService.setData(2);
        }else{
          //this.router.navigate(['/home']);
          this.router.navigateByUrl('/home', { replaceUrl: true });
          this.dataService.setData(2);
        }
      }

      if(res.exito === 2){
        this.dismiss();
        this.correoNoExiste();
      }

      if(res.exito === 3){
        this.dismiss();
        this.correoNoActivo();
      }

      if(res.exito === 4){
        this.dismiss();
        this.passwordMalo();
      }
      });

    }
  }

  async correoNoExiste() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'Error',
      //subHeader: 'Subtitle',
      message: 'El correo ingresado no existe.',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    
  }

  async correoNoActivo() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'Error',
      //subHeader: 'Subtitle',
      message: 'El correo ingresado no esta activo.',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    
  }

  async passwordMalo() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'Error',
      //subHeader: 'Subtitle',
      message: 'Password Incorrecto.',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
   
  }

  errorNotificationPassword(){ 
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Error',
    //   text: 'Password Incorrecto!',
    // })
    
  }

  errorNotificationActivo(){ 
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Error',
    //   text: 'Correo no Activado!',
    // })
    
  }

  errorNotificationCorreo(){ 
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Error',
    //   text: 'Correo no Existe!',
    // })
    
  }

  get errorControl() {
    return this.loginForm.controls;
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'loginAlertComp',
      header: 'Error',
      //subHeader: 'Subtitle',
      message: 'Completa correctamente los campos.',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    
  }

  async modalRec() {
    const modal = await this.modalCtrl.create({
      component: ModalRecuperarPage,
      cssClass: 'modalRecuperarCss',
      initialBreakpoint: 0.8,
      breakpoints: [0, 0.5, 1]
    });
    return await modal.present();
  }

  async modalReg() {
    const modal = await this.modalCtrl.create({
      component: ModalRegistroPage,
      cssClass: 'modalRegistroCss',
      initialBreakpoint: 1,
      breakpoints: [0, 0.5, 1]
    });
    return await modal.present();
  }

}
