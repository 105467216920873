import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HttpClientModule } from '@angular/common/http';
import { LoginPageModule } from './pages/login/login.module';
import { ModalRecuperarPageModule } from './pages/modal-recuperar/modal-recuperar.module';
import { ModalRegistroPageModule } from './pages/modal-registro/modal-registro.module';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { SafePipe } from './pipe/seguridad.pipe';
import { CustomPaginator } from './helpers/CustomPaginatorConfiguration';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha'
import { environment } from 'src/environments/environment';
import { ComponentsModule } from './components/components.module';

@NgModule({
    declarations: [
        AppComponent,
        SafePipe,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        LazyLoadImageModule,
        HttpClientModule,
        NgxPaginationModule,
        SwiperModule,
        LoginPageModule,
        ModalRecuperarPageModule,
        ModalRegistroPageModule, 
        FormsModule, 
        NoopAnimationsModule,
        MatPaginatorModule,
        RecaptchaV3Module,
        ComponentsModule
    ],
    providers: [ {
        provide: RECAPTCHA_V3_SITE_KEY,
        useValue: environment.recaptcha.siteKey,
      },{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy},{ provide : MatPaginatorIntl, useValue : CustomPaginator() },DatePipe],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
