import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import {  Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { SmsManager } from '@byteowls/capacitor-sms';
import { MenuController, Platform } from '@ionic/angular';
import { Usuario } from './Models/usuario.interface';
import { ApiProductosService } from './services/api-productos.service';
import { AuthenticationService } from './services/authentication.service';
import { CarritoServiceService } from './services/carrito-service.service';
import { LoginService } from './services/login.service';
import { DataService } from './services/test.service';
import Fuse from 'fuse.js';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { AnyAaaaRecord } from 'dns';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
  public isDarkTheme: boolean = false;
  usuario!: Usuario;
  nombre!: string;
  apellido!: string;
  iosOrAndroid: boolean;
  datosUsuario: any = [];
  versionAppstore: any;

  public searchbarVisible: boolean = false;
  public totalItems: number = 0;
  public resultadoBusqueda: any = null;
  public txtBuscador: string = '';
  public numeroResultados: number = 0;
  public productos: any = [];
  cargando = false;
  sus : Subscription;
  version = 6;
  respaq:any;
  public options = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    minMatchCharLength: 5,
    // location: 0,
    threshold: 0.5,
    distance: 60,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ['nombre','refi','tags'],
  };

  @ViewChild('pistasCont') pistasCont!:ElementRef<HTMLInputElement>;
  @ViewChild('ud1') ud1!:ElementRef<HTMLInputElement>;
  pistasContIs = false;

  @ViewChild('cardMarcas') cardMarcas!:ElementRef<HTMLInputElement>;
  @ViewChild('ud2') ud2!:ElementRef<HTMLInputElement>;
  cardMarcasIs = false;

  urlsafe! : SafeResourceUrl;
  chat = "https://chat.chatra.io/?isModern=true#hostId=WsCwNXHL8qFQnNjAK&mode=widget&clientId=4qr0d6m_fGVCXNJKniJ97bGayZtpkM3v-GI73rjE&lang=es&currentPage=https%3A%2F%2Flideart.com.mx%2F&currentPageTitle=Lideart&prevPage=&referrer="

  /*get gifs(){
    return this.dataService.historial;
  }*/

  @ViewChild('hedin') hedin!:ElementRef<HTMLInputElement>;
  dataHeader : any;

  @ViewChild('searchInput') searchInput: ElementRef;

  @ViewChild('celMen') celMen!:ElementRef<HTMLInputElement>;
  celMenIs = false;
  

  constructor(private menu: MenuController, private shoppingCart: CarritoServiceService, private renderer: Renderer2, 
    public usuarioService: LoginService, private authService: AuthenticationService, private router: Router, 
    private apiProductos: ApiProductosService,public alertController: AlertController,private location: Location,private platform: Platform, public sanitizer: DomSanitizer,
    private dataService: DataService) {
   


    this.usuarioService.usuario.subscribe(res =>{
      this.usuario = res;
      if (this.usuario) {
        this.checalo();
      }
      
    });
    // const info: DeviceInfo = await Device.getInfo();
    // this.iosOrAndroid = (info.platform === "android" || info.platform === "ios");
    // this.sendSms() 

    this.urlsafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.chat);
  }

  ngOnInit() {


    // this.apiProductos.getProductos().subscribe((response) => {
    //   this.productos = response;
     
    // });
    this.shoppingCart.cartItems.subscribe(d=>{
      this.totalItems = d.length;
    });
    this.checalo();
    this.checaVersion();
    
    this.sus = this.platform.backButton.subscribe(() =>{
      this.location.back();
    });

    /*this.dataService.data$.subscribe((data) => {
      // Do something with the data in the AppComponent
      console.log('Data in AppComponent:', data);
      this.dataHeader = data;
      setTimeout(() => {
        this.datasas();
      }, 1000);
    });*/
    //this.urlsafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.chat);
  }

  datasas(){
    console.log(this.dataHeader);
    if (this.dataHeader == 1) {
      this.renderer.setStyle(this.hedin.nativeElement, 'display', 'none');
    } else if (this.dataHeader == 2) {
      this.renderer.setStyle(this.hedin.nativeElement, 'display', 'block');
    }
  }

  /*chatea(){
    Browser.open({url: this.chat})
  }*/

  handlePistas(){
    if (this.pistasContIs == false) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '0em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-down');

      this.pistasContIs = true;
    }else if (this.pistasContIs == true) {
      this.renderer.setStyle(this.pistasCont.nativeElement, 'height', '8em');
      this.renderer.setStyle(this.pistasCont.nativeElement, 'padding', '1em');
      this.renderer.removeClass(this.ud1.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud1.nativeElement, 'fa-chevron-up');

      this.pistasContIs = false;
    }
  }

  handleCard1(){
    if (this.cardMarcasIs == false) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '12em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '.1em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-up');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-down');

      this.cardMarcasIs = true;
    }else if (this.cardMarcasIs == true) {
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'height', '3em');
      this.renderer.setStyle(this.cardMarcas.nativeElement, 'padding', '0em');
      this.renderer.removeClass(this.ud2.nativeElement, 'fa-chevron-down');
      this.renderer.addClass(this.ud2.nativeElement, 'fa-chevron-up');

      this.cardMarcasIs = false;
    }
  }

  togmenu(){
    if (this.celMenIs == false) {
      this.renderer.setStyle(this.celMen.nativeElement, 'left', '0');
      
      this.celMenIs = true;
    } else if (this.celMenIs == true){
      this.renderer.setStyle(this.celMen.nativeElement, 'left', '-100%');

      this.celMenIs = false;
    }
  }

  mevoy(){
    this.txtBuscador = "";
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/resultados-busqueda']);
    });
  }

  checaVersion(){
    this.usuarioService.checaVersion().subscribe(version =>{
          this.versionAppstore = version;

          if (this.versionAppstore > this.version) {
            this.errorVesion();
            
          }
    })
  }

  async errorVesion() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Nueva Version',
      message: 'Existe una nueva version de esta aplicación, te recomendamos actualizar para evitar errores ¿Deseas Actualizar ahora?',
      buttons: [
        {
          text: 'Confirmar',
          handler: () => {
          window.open("https://play.google.com/store/apps/details?id=io.ionic.lideart", '_system')
          },
        },
      ],
    });
  
    await alert.present();
  }
  

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openSearchBar() {
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
        const event = new Event('touchstart');
        this.searchInput.nativeElement.dispatchEvent(event);
      }, 500);
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      buscador.style.display = "none";
    }
  }

  openSearchBar3() {
    var buscador = document.getElementById('asideMenu');
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      buscador.style.display = "none";
    }
  }

  openSearchBar2(item:any, sku:any) {
   
    var buscador = document.getElementById('buscador');
    if(buscador){
      buscador.style.display = "block";
      this.cargando = true;
        this.apiProductos.esPaquete(sku).subscribe(paquetillo =>{
          this.respaq = paquetillo;
          if (this.respaq == 1) {
            this.cargando = false;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(['/descripcion-paquetes/'+item]);
          });
          }
          if (this.respaq == 0) {
            this.cargando = false;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(['/descripcion-producto/'+item]);
          });
          }
  
        
        })

    
    }
    this.txtBuscador = '';
    this.searchbarVisible = this.searchbarVisible == false ? true : false;
    if (this.searchbarVisible == false){
      this.cargando = true;
      buscador.style.display = "none";
      this.apiProductos.getInfoPaquetes(sku).subscribe(paquetillo =>{
        this.respaq = paquetillo;
        if (this.respaq == 1) {
          this.cargando = false;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/descripcion-paquetes/'+item]);
        });
        }
        if (this.respaq == 0) {
          this.cargando = false;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/descripcion-producto/'+item]);
        });
        }

      
      })
      
    }

  }

  search(event: any) {
    if (event.detail.value != '') {
      const filtro = (event.target as HTMLInputElement).value.trim();
      const fuse = new Fuse(this.productos, this.options);
      
      const pattern = filtro;
      this.resultadoBusqueda = fuse.search(pattern);
      this.numeroResultados = fuse.search(pattern).length;
      this.resultadoBusqueda = this.resultadoBusqueda.slice(0, 100);
     
 
    }
  }

  close(){
    this.menu.close();
    this.usuarioService.logout();
    this.authService.logout();
    this.router.navigateByUrl('/login', { replaceUrl: true });
    setTimeout(() => {
      location.reload();
    }, 1000);
  }

//   sendSms() {
//     const numbers: string[] = ["+52 639 1061558"];
//     SmsManager.send({
//         numbers: numbers,
//         text: "hola compadre, le mando este mensaje",
//     }).then(() => {
//         // success
//     }).catch(error => {
//         console.error(error);
//     });
// }

  closen(){
    this.menu.close();
  }


  checalo() {
    if (this.usuario != null) {
      this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
        this.datosUsuario = data;
  
        this.nombre = this.datosUsuario.nombre;
        this.apellido = this.datosUsuario.apellido;
      });
    } else if (this.usuario == null) {
      this.nombre = 'Bienvenido a';
      this.apellido = 'Lideart';
    }
  }
  


}
